import React, { Dispatch, SetStateAction, useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SEO from '../../../components/SEO'
import Header from '../../../components/Header/Partners'
import Footer from '../../../components/Footer/Partners'
import Hero from '../../../components/Hero/HeroBgImage'
import Accordion from '../../../components/Accordion'
import StepsCards from '../../../components/Cards/StepsCards'
import Text from '../../../components/Text'

import {
  ContentfulCard,
  ContentfulCards,
  ContentfulFeatureContent,
  ContentfulList,
  ContentfulMedia,
  ContentfulPage,
  ContentfulText,
} from '../../../../types/graphql-types'
import {
  AccordionCarrot,
  AccordionStyled,
  AccordionTitle,
  CollapseBody,
} from '../../../styles/support-styles'
import {
  FAQSection,
  FeaturedContent,
  HeroWrapper,
  Intro,
  StepsSection,
} from '../../../styles/cambia-styles'
import LayoutPartners from '../../../components/Layout/Partners/LayoutPartners'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const HeroBar = styled.section`
  ${tw`py-8 text-white text-center`}

  h3 {
    ${tw`text-2xl`}
  }

  p {
    ${tw`mb-0`}
  }
`
const CallUs = styled.section`
  background: #eff2f6;
  ${tw`py-8 lg:py-16 text-center`}
  h2 {
    ${tw`mb-8`}
  }
  h3 {
    ${tw`text-2xl`}
  }
`
export const AdditionalSituations = styled.section`
  background: linear-gradient(
    180deg,
    rgba(248, 250, 251, 0.7) 50%,
    rgba(231, 235, 240, 0.7) 134.5%
  );
  h2 {
    ${tw`mb-8 text-center`}
  }
  p {
    ${tw`text-center mx-auto xl:w-3/5`}
  }
`

export const query = graphql`
  query BlueKCMA {
    contentfulPage(externalName: { eq: "BlueKCMA" }) {
      id
      title
      seoTitle
      seoDescription
      noindex
      externalName
      references {
        ... on ContentfulMedia {
          __typename
          id
          name
          externalName
          altText
          media {
            file {
              url
            }
          }
          heroImageLg: media {
            gatsbyImageData(width: 1920)
          }
          heroImageSm: media {
            gatsbyImageData(width: 768)
          }
        }
        ... on ContentfulList {
          id
          name
          externalName
          openFaq
          items {
            ... on ContentfulText {
              id
              name
              externalName
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        ... on ContentfulFeatureContent {
          name
          externalName
          id
          references {
            ... on ContentfulText {
              name
              __typename
              externalName
              id
              shortSimpleText
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
              richText {
                __typename
                raw
                references {
                  ... on ContentfulGatsbyComponent {
                    __typename
                    contentful_id
                    name
                    externalName
                    id
                    references {
                      ... on ContentfulText {
                        id
                        __typename
                        contentful_id
                        externalName
                        shortSimpleText
                        longSimpleText {
                          childMarkdownRemark {
                            html
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ContentfulMedia {
              name
              externalName
              id
              altText
              media {
                file {
                  url
                }
              }
            }
            ... on ContentfulCards {
              title
              externalName
              id
              cards {
                __typename
                ... on ContentfulCard {
                  id
                  title
                  headline
                  headlineRichText {
                    __typename
                    raw
                    references {
                      ... on ContentfulGatsbyComponent {
                        __typename
                        contentful_id
                        name
                        externalName
                        id
                        references {
                          ... on ContentfulText {
                            id
                            __typename
                            contentful_id
                            externalName
                            shortSimpleText
                            longSimpleText {
                              childMarkdownRemark {
                                html
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  category
                  featuredImage {
                    description
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  media {
                    id
                    name
                    media {
                      id
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        ... on ContentfulText {
          name
          __typename
          externalName
          id
          shortSimpleText
          longSimpleText {
            childMarkdownRemark {
              html
            }
          }
          richText {
            __typename
            raw
            references {
              ... on ContentfulGatsbyComponent {
                __typename
                contentful_id
                name
                externalName
                id
                references {
                  ... on ContentfulText {
                    id
                    __typename
                    contentful_id
                    externalName
                    shortSimpleText
                    longSimpleText {
                      childMarkdownRemark {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const BlueKCMAPage = ({ data }: Props): JSX.Element => {
  // Get FAQ list
  const getFaqList = (data.contentfulPage.references as ContentfulList[]).find(
    faqs => faqs.externalName === 'FAQs'
  )

  // Get Open FAQ radio button
  const openFaq = getFaqList?.openFaq

  // Get first FAQ
  const getFaq = (getFaqList?.items as ContentfulText[]).find(
    faq => faq.externalName === 'Faq'
  )

  // Get first FAQ ID
  const indexFaq = getFaq?.id

  {
    /* If Open FAQ is set to true then indexFaq will be 
    first FAQ Id otherwise it will be set to empty string.*/
  }
  const [activeEventKey, setActiveEventKey] = useState(
    openFaq === true ? `${indexFaq}` : ''
  )

  return (
    <LayoutPartners>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={data.contentfulPage?.noindex as boolean}
      />
      <Header
        classes="border-t-12 md:border-t-45 border-regence-blue"
        logo={(data.contentfulPage.references as ContentfulMedia[]).map(
          logo => {
            if (logo.externalName === 'Brand Logo') {
              return (
                <img
                  key={logo.id}
                  className="h-8 sm:h-auto"
                  src={logo.media?.file?.url as string}
                  alt={logo.altText as string}
                />
              )
            }
          }
        )}
      />
      <main id="main">
        <HeroWrapper>
          <Hero data={data} />
        </HeroWrapper>

        {data.contentfulPage?.references?.map(content => {
          const featureContent = content as ContentfulFeatureContent
          const list = content as ContentfulList
          const text = content as ContentfulText

          if (text.externalName === 'Hero Bar') {
            return (
              <HeroBar className="bg-regence-blue" key={text.id}>
                <div className="container">
                  <Text
                    className="w-full lg:mx-auto lg:w-10/12"
                    long={text}
                    tag="div"
                  />
                </div>
              </HeroBar>
            )
          }

          if (text.externalName === 'Intro') {
            return (
              <Intro key={text.id} className="content-section-full">
                <div className="container">
                  <Text rich={text} />
                </div>
              </Intro>
            )
          }
          if (
            featureContent.externalName === 'Partner Step Cards Feature Content'
          ) {
            return (
              <StepsSection key={featureContent.id} className="content-section">
                {featureContent.references?.map(feature => {
                  const cards = feature as ContentfulCards
                  const text = feature as ContentfulText
                  if (text.externalName === 'Feature Content Copy') {
                    return (
                      <div key={text.id} className="steps-section-intro">
                        <Text rich={text} />
                      </div>
                    )
                  }

                  if (cards.externalName === 'Steps Cards') {
                    return (
                      <ul key={cards.id} className="steps">
                        {(cards.cards as ContentfulCard[]).map(card => {
                          return (
                            <li key={card.id}>
                              <StepsCards
                                title={card.title as string}
                                numberColor="bg-regence-blue"
                                category={card.category as string}
                                headline={card.headline}
                                headlineRichText={card.headlineRichText}
                                altText={
                                  card.featuredImage?.description as string
                                }
                                featuredImage={
                                  card.featuredImage?.gatsbyImageData
                                }
                              />
                            </li>
                          )
                        })}
                      </ul>
                    )
                  }
                })}
              </StepsSection>
            )
          }
          if (
            featureContent.externalName ===
            'Cambia Fall Detection Feature Content'
          ) {
            return (
              <FeaturedContent
                key={featureContent.id}
                className="content-section-py"
              >
                <div className="mb-8 grid content-center lg:w-3/4">
                  {(featureContent.references as ContentfulText[]).map(
                    feature => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return <Text key={feature.id} rich={feature} />
                      }
                    }
                  )}
                </div>
                {(featureContent.references as ContentfulMedia[]).map(
                  feature => {
                    if (feature.externalName === 'Feature Content Image') {
                      return (
                        <img
                          key={feature.id}
                          alt={feature.altText as string}
                          src={feature.media?.file?.url as string}
                        />
                      )
                    }
                  }
                )}
              </FeaturedContent>
            )
          }
          if (text.externalName === 'Call Us') {
            return (
              <CallUs key={text.id}>
                <div className="container">
                  <Text rich={text} />
                </div>
              </CallUs>
            )
          }

          if (list.externalName === 'FAQs') {
            return (
              <FAQSection key={list.id} className="content-section-py">
                <h2 className="mb-8 text-center">{list.name}</h2>
                {(list.items as ContentfulText[]).map(item => {
                  return (
                    <Accordion
                      activeEventKey={activeEventKey}
                      onToggle={
                        setActiveEventKey as unknown as Dispatch<
                          SetStateAction<string | undefined>
                        >
                      }
                      key={item.id}
                      className={''}
                    >
                      <AccordionStyled>
                        <Accordion.Toggle
                          onClick={(
                            event: React.MouseEvent<
                              HTMLButtonElement,
                              MouseEvent
                            >
                          ) => void event}
                          eventKey={item?.id}
                          data-analytics-faq={item?.name}
                          aria-controls={`${item?.id}-controls`}
                          aria-expanded={
                            activeEventKey === item?.id ? true : false
                          }
                          id={item?.id}
                          className="flex w-full items-center justify-between p-4"
                        >
                          <AccordionTitle>
                            <div>
                              <p>{item?.name}</p>
                            </div>
                          </AccordionTitle>
                          <AccordionCarrot
                            className={`icon-angle-down`}
                          ></AccordionCarrot>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey={item?.id}
                          role="region"
                          aria-labelledby={item?.id}
                          id={`${item?.id}-controls`}
                        >
                          <CollapseBody
                            className="px-4"
                            dangerouslySetInnerHTML={{
                              __html: item.longSimpleText?.childMarkdownRemark
                                ?.html as string,
                            }}
                          ></CollapseBody>
                        </Accordion.Collapse>
                      </AccordionStyled>
                    </Accordion>
                  )
                })}
              </FAQSection>
            )
          }
          if (
            featureContent.externalName ===
            'Additional Situations Featured Content'
          ) {
            return (
              <AdditionalSituations
                key={featureContent.id}
                className="content-section-full"
              >
                <div className="container">
                  {(featureContent.references as ContentfulText[]).map(
                    feature => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return (
                          <Text key={feature.id} long={feature} tag="div" />
                        )
                      }
                    }
                  )}
                </div>
              </AdditionalSituations>
            )
          }
          if (text.externalName === 'Page Disclaimer') {
            return (
              <section key={text.id} className="content-section-py">
                <div className="container">
                  <Text className="w-9/12" long={text} tag="div" />
                </div>
              </section>
            )
          }
        })}
      </main>
      <Footer color="bg-regence-blue" data={data} />
    </LayoutPartners>
  )
}

export default BlueKCMAPage
